import { PublicClientApplication } from '@azure/msal-browser';
import { internalPowerupMsalConfig, isInternal, msalConfig } from 'auth/constants/config';
import config from 'app/constants/config';

const defaultClientId = config.internalDefaultCustomerId;

type IdTokenClaims = {
  extension_ClientID: number;
};

export const msalInstance = new PublicClientApplication(isInternal ? internalPowerupMsalConfig : msalConfig);

export const setClientId = (clientId: number) => {
  localStorage.setItem('x-client-id', clientId.toString());
  window.location.reload();
};

const clientId = (() => {
  if (isInternal) {
    return Number(localStorage.getItem('x-client-id')) || defaultClientId;
  }

  return (msalInstance.getActiveAccount()?.idTokenClaims as IdTokenClaims)?.extension_ClientID;
})();

export { clientId };

export enum AlertLevel {
  MALFUNCTION = 1,
  CRITICAL = 2,
  OK = 0,
  UNKNOWN = -1,
}

export enum AlertType {
  ci = '1',
  overTemp = '2',
  overVolt = '3',
  underVolt = '10',
  others = '4',
}

export enum AlertTextPlural {
  zero = 'zero',
  one = 'one',
  many = 'many'
}

export const alertTypeKey = (type: AlertType) => Object.keys(AlertType)[Object.values(AlertType).indexOf(type)]

// @TODO CHECK HOW ALERTS WILL BE SEND
export enum AlertIconType {
  OVERHEAT = 'overheat',
  OVERTVOLT = 'overvolt',
  IMBALANCE = 'imbalance',
}

export const alertName = ['Nominal', 'Malfunction', 'Critical'];

export const alertTypeName = new Map<AlertType, string>([
  [AlertType.ci, 'Cell imbalance'],
  [AlertType.overTemp, 'Over-temperature'],
  [AlertType.overVolt, 'Over-voltage'],
  [AlertType.others, 'Others'],
  [AlertType.underVolt, 'Under-voltage'],
]);

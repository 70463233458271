import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { getAppInsights, ai } from 'app/utils/telemetry';

ai.initialize();
const appInsights = getAppInsights();

export const logger = {
  log: (message: string) => {
    appInsights?.trackTrace({ message, severityLevel: SeverityLevel.Verbose });
  },
  info: (message: string) => {
    appInsights?.trackTrace({ message, severityLevel: SeverityLevel.Information });
  },
  error: (message: string) => {
    appInsights?.trackTrace({ message, severityLevel: SeverityLevel.Critical });
  },
};

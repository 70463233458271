import { IReverseEss, IEss, EncapsulatedQueryResponse, TimeRange } from 'app/types';
import { usePark } from 'park/components/ParkProvider';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuthApi from 'app/hooks/useApi';
import { BatteryType, typeShorten } from 'app/constants/battery';
import { clientId } from 'auth';

// For parks in full view, we do not have the usual start and stop dates,
// as the physmetrics are spread over multiple assets
//  => Use a default range that spans the century this code was written in
const defaultTimeRange: TimeRange = {
  first: "2000-01-01",
  last: "2100-01-01",
};

export interface IEmptyItem {
  type: null | string;
  id: null;
  parent: null;
  name: string;
  children: null;
  hierarchyid: null | string;
  timeRange: TimeRange | null;
}

export interface IParkItem {
  type: string;
  id: string;
  parent: null;
  name: string;
  children: IEss[];
  hierarchyid: string;
  timeRange: TimeRange | null;
}

export type ItemTypes = Record<'e' | 'c' | 'r' | 'm', string>;
export const itemTypes = {
  e: 'ess',
  c: 'container',
  r: 'rack',
  m: 'module',
};

type Context = IReverseEss | IEmptyItem | IParkItem;

const StateContext = createContext<Context>({
  type: null,
  id: null,
  parent: null,
  name: "",
  children: null,
  hierarchyid: null,
  timeRange: null,
});
StateContext.displayName = "ParamsItem"

/**
 * Flow
 * HierarchyId is updated
 * => currentItem is set to empty value (emptyItem)
 * => matchItem is set to matched item with same hierarchyId
 * => query lastUpdate is sent
 * => receive request response -> itemTimeRange is updated
 * => we have all conditions to set the current item (matchItem + itemTimeRange for the same item)
 */
function ParamsItemProvider({ children }: {children: ReactNode}) {
  const params = useParams();
  const { park } = usePark();

  const itemsKeys = Object.values(params);
  const hierarchyid = itemsKeys.join('_');

  const lastItemArrayType = (itemsKeys[itemsKeys.length - 1]?.charAt(0) as keyof ItemTypes) || 'e';
  const lastItemType = itemTypes[lastItemArrayType];

  // Empty value to init and reset matchedItem and currentItem
  const emptyItem = useMemo(() => (
    {
      type: lastItemType || null,
      id: null,
      parent: null,
      name: '',
      children: null,
      hierarchyid: hierarchyid || null,
      timeRange: null,
    }
  ), [lastItemType, hierarchyid]);

  const [currentItem, setCurrentItem] = useState(emptyItem as IReverseEss | IEmptyItem | IParkItem);

  // Intermediate state before updating currentItem
  const [matchedItem, setMatchedItem] = useState<IReverseEss | IEmptyItem | IParkItem>(emptyItem);

  const runDownPark = useCallback(
    (items: IReverseEss[], type: string): IReverseEss[] => {
      if (items.length === 0) {
        return [];
      }
      if (type === items[0].type) {
        return items;
      }
      return runDownPark(
        items.reduce<IReverseEss[]>((acc, cur) => {
          const curConcat = acc.concat(
            cur?.children?.map((item) => {
              const parent = cur.parent || [];
              return {
                ...item,
                parent: [...parent, { id: cur.id, type: cur.type, hierarchyid: cur.hierarchyid, name: cur.name }],
              };
            }) || [],
          );
          return curConcat;
        }, []),
        type,
      );
    },
    [],
  );

  const { data: itemTimeRange } = useAuthApi<EncapsulatedQueryResponse<TimeRange[]>, TimeRange>(
    `item_time_range-${matchedItem.hierarchyid}`,
    `/dyndata/${typeShorten[matchedItem.type as BatteryType]}/${matchedItem.id}/kpi/lastupdate`,
    {
      enabled: !!matchedItem.id && !!matchedItem.hierarchyid,
      select: res => res.data[0],
    },
  );

  // Reset CurrentItem and retrieve item which correspond to the hierarchyId in the park
  useEffect(() => {
    setCurrentItem(emptyItem)
    const items = runDownPark(park, lastItemType || '');
    const match = items.find((item: IReverseEss) => item.hierarchyid === hierarchyid);

    setMatchedItem(match || emptyItem)
  }, [emptyItem, hierarchyid, lastItemType, park, runDownPark]);

  // Set the current item once we have matchedItem and itemTimeRange updated 
  useEffect(() => {
    if (!hierarchyid && clientId) {
      setCurrentItem({
        type: 'park',
        id: String(clientId),
        parent: null,
        name: '',
        children: park,
        hierarchyid: `p${clientId}`,
        timeRange: defaultTimeRange,
      } as IParkItem) 
      return;
    }

    // We only change the current item, when we have an item that match the hierarchyid and its timeRange
    if (hierarchyid !== matchedItem.hierarchyid || !itemTimeRange)
      return;

    setCurrentItem({
      ...matchedItem,
      timeRange: itemTimeRange
    });
  }, [hierarchyid, itemTimeRange, matchedItem, park])

  return <StateContext.Provider value={currentItem}>{children}</StateContext.Provider>;
};

export { ParamsItemProvider };

export default () => useContext(StateContext);

import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import config from 'app/constants/config';

let reactPlugin: ReactPlugin | ITelemetryPlugin | null = null;
let appInsights: ApplicationInsights | null = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */

const createTelemetryService = () => {
  const initialize = () => {
    if (!config.enableAppInsight) return;

    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
      config: {
        distributedTracingMode: 2,    // DistributedTracingModes.W3C
        excludeRequestFromAutoTrackingPatterns: ['auth0.com'],
        instrumentationKey: config.azure.insightInstrumentationKey,
        connectionString: config.azure.insightConnectionString,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
        enableAutoRouteTracking: true,
        loggingLevelConsole: config.azure.levelConsole,
        loggingLevelTelemetry: config.azure.levelTelemetry,
        extensions: [reactPlugin],
      },
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView();
  };

  return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;

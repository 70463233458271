import { useState, useContext, createContext, ReactNode, useCallback, useMemo, useRef } from 'react';
import Modal from '@dotmind/react-modals';

import Multiply from 'app/components/icons/Multiply';

import styles from 'app/components/modal/styles.module.scss';

type Context = {
  opened: boolean;
  open: (component: ReactNode) => void;
  close: () => void;
  modalRef?: React.RefObject<HTMLDivElement>;
};

const initialContext: Context = {
  opened: false,
  open: () => undefined,
  close: (): ReactNode => undefined,
};

interface IProps {
  children: ReactNode;
}

const StateContext = createContext<Context>(initialContext);
StateContext.displayName = "Modal"

function ModalProvider({ children }: IProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [innerComponent, injectComponent] = useState<ReactNode>();
  const [opened, open] = useState(false);

  const modalRef = useRef(null);

  const openCallback = useCallback((component: ReactNode) => {
    open(true);
    injectComponent(component);
  }, []);

  const closeCallback = useCallback(() => {
    open(false);
    injectComponent(undefined);
  }, []);

  const value = useMemo(
    () => ({
      opened,
      open: openCallback,
      close: closeCallback,
      modalRef,
    }),
    [opened, openCallback, closeCallback],
  );

  return (
    <StateContext.Provider value={value}>
      <>
        {children}
        <Modal
          modalOpen={opened}
          onClose={closeCallback}
          containerZIndex={10}
          contentClassName={styles.customModal}
          closeButtonElement={<Multiply height={'24'} width={'24'} fill={'var(--color-grey-5)'} />}>
          {innerComponent}
        </Modal>
      </>
    </StateContext.Provider>
  );
}

export const useModal = () => useContext(StateContext);

export default ModalProvider;

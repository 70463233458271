export enum alertType {
  WARNING = '1',
  ERROR = '2',
  OK = '0',
}

export enum AlertFilter {
  ONGOING = 'ongoing',
  PAST = 'past',
  ALL = 'all',
}

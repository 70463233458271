import { ReactNode, useContext, createContext, useState, useMemo, useCallback } from 'react';

import { typeShorten } from 'app/constants/battery';
import { IEss, IPark } from 'app/types';
import useAuthApi from 'app/hooks/useApi';
import { ApplicationMode } from 'app/utils/applicationMode';
import { clientId } from 'auth';

type Context = {
  park: IEss[];
  flattenPark: IEss[];
  applicationMode?: ApplicationMode;
  getNodeClientName: (hierarchyId: string) => string;
  getLeafNode: (essId: string) => string | null;
};

const defaultContext = {
  park: [],
  flattenPark: [],
  applicationMode: undefined,
  getNodeClientName: () => '',
  getLeafNode: () => null
};

const StateContext = createContext<Context>(defaultContext);
StateContext.displayName = "Park"

interface IProps {
  children: ReactNode;
}

function propagate_ess_id_down_the_tree(level?: IEss[], ess_id?: string): IEss[] {
  if (!level || level.length === 0) {
    return [];
  }

  return level.map((child: IEss) => ({
    ...child,
    ess: child.type === 'ess' ? child.id : ess_id,
    children: propagate_ess_id_down_the_tree(child.children, child.type === 'ess' ? child.id : ess_id),
  }));
}

function getLeaf(node: IEss): string {
  if (node.children === undefined || node.children.length === 0)
    return node.type
  return getLeaf(node.children[0])
}

function ParkProvider({ children }: IProps) {
  const [park, setPark] = useState<IEss[]>([]);
  const [applicationMode, setApplicationMode] = useState<ApplicationMode | undefined>();

  const { isLoading } = useAuthApi('park', `/staticdata/${typeShorten.park}/${clientId}/park`, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: ({ data: _park }: { data: IPark }) => {
      setPark(propagate_ess_id_down_the_tree(_park.esslist));
      setApplicationMode(_park.application_mode as ApplicationMode);
    },
    onError: (error) => {
      // @todo
      // eslint-disable-next-line no-console
      console.log('error:', error);
    },
    enabled: Boolean(clientId)
  });

  const flattenPark = useMemo(
    () => [
      ...park,
      ...park.flatMap((ess) => ess.children || []),
      ...park.flatMap((ess) => ess.children || []).flatMap((containers) => containers?.children || []),
      ...park
        .flatMap((ess) => ess.children || [])
        .flatMap((containers) => containers?.children || [])
        .flatMap((racks) => racks?.children || []),
    ],
    [park],
  );

  const getNodeClientName = useCallback((hierarchyId: string) =>
    flattenPark.find((node) => node.hierarchyid === hierarchyId)?.name || hierarchyId
  , [flattenPark]);

  const getLeafNode = useCallback((essId: string): string | null => {
    const essTree = park.find((ess) => ess.id === essId)
    if (essTree)
      return getLeaf(essTree)
    return null
  }, [park]);

  const context = useMemo(() => ({
    park,
    flattenPark,
    applicationMode,
    getNodeClientName,
    getLeafNode
  }), [applicationMode, flattenPark, park, getNodeClientName, getLeafNode]);

  // @todo UI
  if (isLoading) {
    return <StateContext.Provider value={context}>{children}</StateContext.Provider>;
  }

  return <StateContext.Provider value={context}>{children}</StateContext.Provider>;
}

export const usePark = () => useContext(StateContext);

export default ParkProvider;

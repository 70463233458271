type DefaultConfig = {
  baseUrl: string;
  host: string;
  root: string;
  azure: IAzureConfig;
  azureInternal: Partial<IAzureConfig>;
  supportUrl: string;
  configSettingsUrl: string;
  enableAppInsight: boolean;
  hotjarSiteId: number;
  fleetDocsURL: string;
  docsURL: string;
  fleetDocsURLSudo: string;
  docsURLSudo: string;
  internalDefaultCustomerId: number;
  powerupSudoDomain: string;
};

type IAzureConfig = {
  applicationId: string;
  tenantId: string;
  b2cDomain: string;
  scope: string;
  redirectUri: string;
  insightConnectionString: string;
  insightInstrumentationKey: string;
  levelConsole: number;
  levelTelemetry: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const runtimeVars: any = (window as any)?._env || {};

const AzureConfig: IAzureConfig = {
  applicationId: String(runtimeVars.REACT_APP_AZURE_APPLICATION_ID || process.env.REACT_APP_AZURE_APPLICATION_ID),
  tenantId: String(runtimeVars.REACT_APP_AZURE_TENANT_ID || process.env.REACT_APP_AZURE_TENANT_ID),
  b2cDomain: String(runtimeVars.REACT_APP_AZURE_B2C_DOMAIN || process.env.REACT_APP_AZURE_B2C_DOMAIN),
  scope: String(runtimeVars.REACT_APP_AZURE_SCOPE || process.env.REACT_APP_AZURE_SCOPE),
  redirectUri: String(runtimeVars.REACT_APP_AZURE_REDIRECT_URI || process.env.REACT_APP_AZURE_REDIRECT_URI),
  insightConnectionString: String(runtimeVars.REACT_APP_CONNECTION_STRING || process.env.REACT_APP_CONNECTION_STRING),
  insightInstrumentationKey: String(runtimeVars.REACT_APP_INSTRUMENTATION_KEY || process.env.REACT_APP_INSTRUMENTATION_KEY),
  levelConsole: Number(runtimeVars.REACT_APP_LEVEL_CONSOLE || process.env.REACT_APP_LEVEL_CONSOLE),
  levelTelemetry: Number(runtimeVars.REACT_APP_LEVEL_TELEMETRY || process.env.REACT_APP_LEVEL_TELEMETRY),
};

const AzureInternalConfig: Partial<IAzureConfig> = {
  applicationId: String(
    runtimeVars.REACT_APP_AZURE_INTERNAL_APPLICATION_ID || process.env.REACT_APP_AZURE_INTERNAL_APPLICATION_ID,
  ),
  tenantId: String(runtimeVars.REACT_APP_AZURE_INTERNAL_TENANT_ID || process.env.REACT_APP_AZURE_INTERNAL_TENANT_ID),
  scope: String(runtimeVars.REACT_APP_AZURE_INTERNAL_SCOPE || process.env.REACT_APP_AZURE_INTERNAL_SCOPE),
  redirectUri: String(runtimeVars.REACT_APP_AZURE_INTERNAL_REDIRECT_URI || process.env.REACT_APP_AZURE_INTERNAL_REDIRECT_URI),
};

const config: DefaultConfig = {
  baseUrl: String(runtimeVars.REACT_APP_API_URL || process.env.REACT_APP_API_URL),
  supportUrl: String(runtimeVars.REACT_APP_SUPPORT_URL || process.env.REACT_APP_SUPPORT_URL || ''),
  host: String(runtimeVars.REACT_APP_HOST || process.env.HOST),
  root: '../',
  azure: AzureConfig,
  azureInternal: AzureInternalConfig,
  configSettingsUrl: String(runtimeVars.REACT_APP_PUBLIC_SETTINGS_BASE_URL || process.env.REACT_APP_PUBLIC_SETTINGS_BASE_URL),
  enableAppInsight: Boolean(runtimeVars.REACT_APP_ENABLE_APPINSIGHT || process.env?.REACT_APP_ENABLE_APPINSIGHT),
  hotjarSiteId: Number(runtimeVars.REACT_APP_HOTJAR_SITE_ID || process.env.REACT_APP_HOTJAR_SITE_ID),
  fleetDocsURL: String(runtimeVars.REACT_APP_DOC_FLEET_URL || process.env.REACT_APP_DOC_FLEET_URL),
  docsURL: String(runtimeVars.REACT_APP_DOC_URL || process.env.REACT_APP_DOC_URL),
  fleetDocsURLSudo: String(runtimeVars.REACT_APP_DOC_FLEET_URL_SUDO || process.env.REACT_APP_DOC_FLEET_URL_SUDO),
  docsURLSudo: String(runtimeVars.REACT_APP_DOC_URL_SUDO || process.env.REACT_APP_DOC_URL_SUDO),
  internalDefaultCustomerId: Number(
    runtimeVars.REACT_APP_INTERNAL_DEFAULT_CUSTOMER_ID || process.env.REACT_APP_INTERNAL_DEFAULT_CUSTOMER_ID,
  ),
  powerupSudoDomain: String(runtimeVars.REACT_APP_AZURE_POWERUP_SUDO_DOMAIN || process.env.REACT_APP_AZURE_POWERUP_SUDO_DOMAIN),
};

export default config;

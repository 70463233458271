import { Suspense } from 'react';

interface IProps {
  children: JSX.Element;
}

// @todo add fallback UI
function LazyComponentWrapper({ children }: IProps) {
  return <Suspense fallback={<div>Chargement...</div>}>{children}</Suspense>;
}

export default LazyComponentWrapper;

import { AccountInfo, InteractionRequiredAuthError, BrowserAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import api from 'app/infrastructure/http/api';
import { logger } from 'app/utils/logger';
import { clientId } from 'auth';
import { loginRequest } from 'auth/constants/config';
import { useQuery, UseQueryOptions } from 'react-query';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useAuthApi = <TData=any,TSelect=TData>(
  requestKey: string,
  url: string,
  options?: Omit<UseQueryOptions<TData,unknown,TSelect>, 'queryKey' | 'queryFn'>,
  method = 'get',
) => {
  const { instance, inProgress } = useMsal();
  const account = instance.getActiveAccount();

  return useQuery<TData, unknown, TSelect>(
    requestKey,
    () =>
      instance
        .acquireTokenSilent({
          scopes: loginRequest.scopes,
          account: account as AccountInfo,
        })
        .then((response) =>
          api[method](url, {
            headers: {
              Authorization: `Bearer ${response.accessToken}`,
              'x-client-id': clientId,
            },
          }),
        )
        .catch((error) => {
          if (inProgress === InteractionStatus.None 
              && error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(loginRequest);
          }
          return Promise.reject(error);
        }),
    {
      onError: (error) => logger.error(`Error: useAuthApi ${error}`),
      staleTime: 3600000, // Data gets refreshed on windows (or tab) focus. With staleTime, refresh only out of date requests.
      retry: false,
      ...options,
      enabled: Boolean(account) && inProgress === InteractionStatus.None && (options?.enabled ?? true),
    },
  );
};

export default useAuthApi;

import i18n from 'i18n-js';
import fr from 'translations/fr.json';

const locale = 'fr';

// Set i18n translations
i18n.locale = locale;
i18n.fallbacks = true;
i18n.translations = { fr };
i18n.missingTranslation = (scope) => {
  console.warn(`texte manquant pour la clé de traduction : ${scope}`);
  return '';
};

import dayjs from 'dayjs';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DateFormat, dateToStr } from 'app/components/date';
import { TimeFilterPeriods, TimeFilterPeriodType } from 'dashboard/constants/times';
import useParamsItem from 'dashboard/hooks/useParamsItem';

type Context = {
  selectedMasterPeriod: TimeFilterPeriodType;
  setMasterPeriod: (period: TimeFilterPeriodType) => void;
  periods: TimeFilterPeriodType[];
  isActiveMasterPeriod: (period: TimeFilterPeriodType) => boolean;
  getPeriodTimeRange: (period: TimeFilterPeriodType) => string | undefined;
};

const defaultContext = {
  selectedMasterPeriod: TimeFilterPeriods[0],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setMasterPeriod: (period: TimeFilterPeriodType) => undefined,
  periods: TimeFilterPeriods,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isActiveMasterPeriod: (_period: TimeFilterPeriodType) => false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPeriodTimeRange: (_period: TimeFilterPeriodType) => undefined,
};

const StateContext = createContext<Context>(defaultContext);
StateContext.displayName = 'TimeFilter';

interface IProps {
  children: ReactNode;
}

function TimeFilterProvider({ children }: IProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { timeRange } = useParamsItem();

  const getPeriodTimeRange = useCallback((_period: TimeFilterPeriodType) => {
    let range;

    if (timeRange?.first && timeRange?.last && _period.label === 'full') {
      range = {
        start: dayjs(timeRange.first).toISOString(),
        stop: dayjs(timeRange.last).toISOString(),
      }
    } else if (_period.label === "custom") {
      return _period.startStop;
    } else if (_period.label !== 'full') {
      range = {
        start: dayjs().subtract(_period.date.value, _period.date.unit as dayjs.ManipulateType).toISOString(),
        stop: dayjs.utc().subtract(1, 'day').endOf('day').toISOString(),
      }
    }

    return range ? new URLSearchParams(range).toString() : undefined;
  }, [timeRange]);

  const getMasterPeriodFromSearchParams = () => {
    const searchParamsMasterPeriod = searchParams.get('masterPeriod');

    let masterPeriod = TimeFilterPeriods.find(({value})=>value===searchParamsMasterPeriod);

    if (!masterPeriod) {
      const dates = searchParamsMasterPeriod?.split("|").slice(0,2).map(dayjs);
      
      if (dates && dates.every(d=>d.isValid())) {
        const startFormatted = dateToStr(dates[0], DateFormat.REQUEST_DATE);
        const stopFormatted = dateToStr(dates[1], DateFormat.REQUEST_DATE);
        const requestParams = new URLSearchParams({start: startFormatted, stop: stopFormatted}).toString();
    
        return {
          label: "custom",
          date: { value: Math.random(), unit: "", },
          value: `${startFormatted}|${stopFormatted}`,
          startStop: requestParams,
        };
      }

      [masterPeriod] = TimeFilterPeriods;
    }

    return {
      ...masterPeriod,
      startStop: getPeriodTimeRange(masterPeriod),
    }
  }

  const [selectedMasterPeriod, setSelectedMasterPeriod] = useState<TimeFilterPeriodType>(getMasterPeriodFromSearchParams());

  useEffect(() => { // Update selectedMasterPeriod if timeRange was not available during getMasterPeriodFromSearchParams call
    if (selectedMasterPeriod.label === "full" && !timeRange) {
      // on currentItem change, wait for timeRange query to determine start and stop time of FULL period
      setSelectedMasterPeriod(period => ({...period, startStop: undefined }));
      return;
    }
    setSelectedMasterPeriod(period=>({...period, startStop: getPeriodTimeRange(period)}));
  }, [timeRange, getPeriodTimeRange, selectedMasterPeriod.label]);

  const [periods] = useState<TimeFilterPeriodType[]>(TimeFilterPeriods);

  const setMasterPeriod = useCallback((v: TimeFilterPeriodType) => {
    setSelectedMasterPeriod({...v, startStop: getPeriodTimeRange(v)});
    searchParams.set('masterPeriod', v.value);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams, setSelectedMasterPeriod, getPeriodTimeRange])

  const isActiveMasterPeriod = useCallback(
    (_period: TimeFilterPeriodType) => selectedMasterPeriod && _period.label === selectedMasterPeriod.label,
    [selectedMasterPeriod],
  );

  const context = useMemo(
    () => ({ selectedMasterPeriod, setMasterPeriod, periods, isActiveMasterPeriod, getPeriodTimeRange }),
    [selectedMasterPeriod, setMasterPeriod, periods, isActiveMasterPeriod, getPeriodTimeRange],
  );

  return <StateContext.Provider value={context}>{children}</StateContext.Provider>;
}

export const useTimeFilter = () => useContext(StateContext);

export default TimeFilterProvider;

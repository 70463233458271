import React, { PureComponent } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'app/utils/telemetry';
import { logger } from 'app/utils/logger';
import Router from 'app/components/Router';

type AppProps = {
  pca: IPublicClientApplication;
};

const queryClient = new QueryClient();

class App extends PureComponent<AppProps, unknown> {
  componentDidCatch(error: unknown, info: unknown) {
    logger.error(`Error: ${error} ${info}`);
  }

  render() {
    const { pca } = this.props;

    return (
      <MsalProvider instance={pca}>
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </MsalProvider>
    );
  }
}

export default withAITracking(ai.reactPlugin as ReactPlugin, App);

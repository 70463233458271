import { Configuration, PopupRequest, LogLevel } from '@azure/msal-browser';
import config from 'app/constants/config';

// @todo
// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: config.azure.applicationId,
    authority: `https://${config.azure.b2cDomain}/${config.azure.tenantId}/B2C_1_SI`,
    knownAuthorities: [config.azure.b2cDomain],
    redirectUri: config.azure.redirectUri,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
    secureCookies: false,
  },
  system: {
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }

        /* eslint-disable indent, no-useless-return, no-console */
        switch (level) {
          case LogLevel.Error:
            if (config.azure.levelConsole < 1) return;
            console.error(message);
            return;
          case LogLevel.Info:
            if (config.azure.levelConsole < 2) return;
            console.info(message);
            return;
          case LogLevel.Verbose:
            if (config.azure.levelConsole < 2) return;
            console.debug(message);
            return;
          case LogLevel.Warning:
            if (config.azure.levelConsole < 1) return;
            console.warn(message);
            return;
          default:
            return;
        }
        /* eslint-enable indent */
      },
    },
  },
};

export const isInternal = window.location.hostname === config.powerupSudoDomain;

export const internalPowerupMsalConfig: Configuration = {
  auth: {
    clientId: String(config.azureInternal.applicationId),
    authority: `https://login.microsoftonline.com/${config.azureInternal.tenantId}`,
    redirectUri: String(`${config.azureInternal.redirectUri}`),
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
    secureCookies: false,
  },
  system: {
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }

        /* eslint-disable indent, no-useless-return, no-console */
        switch (level) {
          case LogLevel.Error:
            if (config.azure.levelConsole < 1) return;
            console.error(message);
            return;
          case LogLevel.Info:
            if (config.azure.levelConsole < 2) return;
            console.info(message);
            return;
          case LogLevel.Verbose:
            if (config.azure.levelConsole < 2) return;
            console.debug(message);
            return;
          case LogLevel.Warning:
            if (config.azure.levelConsole < 1) return;
            console.warn(message);
            return;
          default:
            return;
        }
        /* eslint-enable indent */
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: isInternal ? [String(config.azureInternal.scope)] : [`https://${config.azure.tenantId}/${config.azure.scope}`],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
};

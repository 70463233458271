import dayjs from 'dayjs';

export enum DateFormat {
  DATE = 'DD/MM/YYYY',
  DATETIME = 'DD/MM/YYYY HH:mm:ss',
  REQUEST_DATE = 'YYYY-MM-DD' // Format for custom request with start-stop
}

export function dateToStr(
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  format: DateFormat = DateFormat.DATE
): string {
  return dayjs(date).format(format)
}
import { ReactNode, useContext, createContext, useMemo } from 'react';
import { IAlert } from 'app/types';
import { useTimeFilter } from 'dashboard/components/TimeFilterProvider';
import useParamsItem from 'dashboard/hooks/useParamsItem';
import useAuthApi from 'app/hooks/useApi';
import { BatteryType, typeShorten } from 'app/constants/battery';

type Context = {
  alerts: IAlert[];
};

const defaultContext = {
  alerts: [],
};

const StateContext = createContext<Context>(defaultContext);
StateContext.displayName = "AlertItem"

interface IProps {
  children: ReactNode;
}

function AlertItemProvider({ children }: IProps) {
  const { selectedMasterPeriod } = useTimeFilter();
  const { type, id, hierarchyid } = useParamsItem();

  const { data } = useAuthApi(
    `alertitem-${hierarchyid}-${selectedMasterPeriod.value}`,
    `dyndata/${typeShorten[type as BatteryType]}/${id}/alerts/all?${selectedMasterPeriod.startStop}&range=0-100000`,
    {
      enabled: !!id && !!selectedMasterPeriod.startStop,
    },
  );

  const context = useMemo(() => ({ alerts: data?.data?.items || [] }), [data]);

  return <StateContext.Provider value={context}>{children}</StateContext.Provider>;
}

export const useAlertsItem = () => useContext(StateContext);

export default AlertItemProvider;

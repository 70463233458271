import axios, { AxiosRequestConfig } from 'axios';
import ApiExecutor from '@dotmind/utils/dist/react/ApiExecutor';

import type { AccountInfo } from '@azure/msal-browser';
import { clientId, msalInstance } from 'auth';
import { loginRequest } from 'auth/constants/config';

import { TIMEOUT } from 'app/constants/api';
import config from 'app/constants/config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RequestType = AxiosRequestConfig | Record<string, any>;

function apiInstance() {
  return axios.create({
    baseURL: config.baseUrl,
    timeout: TIMEOUT,
  });
}

function batteryInsightApi() {
  return axios.create({
    baseURL: config.baseUrl,
    timeout: TIMEOUT,
  });
}

const batteryInsightAuthApi = (() => {
  const instance = axios.create({
    baseURL: `${config.baseUrl}`,
    timeout: TIMEOUT,
  });
  instance.interceptors.request.use( async (reqConfig) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accessToken = await msalInstance.acquireTokenSilent({
      scopes: loginRequest.scopes,
      account: activeAccount as AccountInfo,
    }).then((response) => response.accessToken);

    // eslint-disable-next-line no-param-reassign
    if (accessToken) reqConfig.headers = { 
      ...reqConfig?.headers, 
      'x-client-id': clientId,
      Authorization: `Bearer ${accessToken}`
    };
    
    return reqConfig;
  })

  return instance;
})();

function api() {
  const instance = apiInstance();
  const executor = new ApiExecutor(instance);

  // @XXX : Type of axios + dotmind/ApiExecutor
  /* eslint-disable */
  // @ts-ignore
  function handle(request) {
    // eslint-disable-next-line
    // @ts-ignore
    return request.then((ref) => {
      const { data, _bodyInit: bodyInit } = ref;
      return bodyInit || data;
    });
  }
  // eslint-disable-next-line
  // @ts-ignore
  function del(url, options) {
    return handle(instance.delete(url, options));
  }

  // eslint-disable-next-line
  // @ts-ignore
  function rawGet(url, params, config) {
    // eslint-disable-next-line
    // @ts-ignore
    return instance.get(url, params, config);
  }

  /* eslint-enable */

  return {
    ...executor,
    del,
    put: instance.put,
    rawGet,
  };
}

export default api();
export { batteryInsightApi, batteryInsightAuthApi };
export type TimeFilterPeriodType = {
  label: string;
  value: string;
  startStop?: string;
  date: {
    value: number;
    unit: string;
  };
};

export const TimeFilterPeriods: TimeFilterPeriodType[] = [
  {
    label: '6m',
    value: '6m',
    date: {
      value: 6,
      unit: 'M',
    },
  },
  {
    label: '1y',
    value: '1y',
    date: {
      value: 1,
      unit: 'y',      
    },
  },
  {
    label: '2y',
    value: '2y',
    date: {
      value: 2,
      unit: 'y',
    },
  },
  {
    label: '5y',
    value: '5y',
    date: {
      value: 5,
      unit: 'y',      
    },
  },
  {
    label: '10y',
    value: '10y',
    date: {
      value: 10,
      unit: 'y',      
    },
  },
  {
    label: 'full',
    value: 'full',
    date: {
      value: 100,
      unit: 'y',      
    },
  },
];

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AlertLevel } from 'alerts/constants/alerts';
import { BatteryType, typeShorten } from 'app/constants/battery';
import useAuthApi from 'app/hooks/useApi';
import useParamsItem from 'dashboard/hooks/useParamsItem';
import type { SelectedTime } from 'dashboard/types/Timeline';
import { useTimeFilter } from './TimeFilterProvider';

dayjs.extend(utc);
const dateFormat = "YYYY-MM-DD";

type TimeRange = {
  start: string;
  stop: string;
  maxlevel: number;
};
type AlertsPerPeriod = {
  success: boolean;
  data: TimeRange[];
}

type Context = {
  selectedTime: SelectedTime;
  selectedStopTime: SelectedTime;
  setSelectedTime: (date: string, storeInSearchParams?: boolean) => void;
  timeline: { start: string, stop: string, value: number }[] | null;
};

const defaultContext = {
  selectedTime: dayjs(-1),
  selectedStopTime: dayjs(0),
  setSelectedTime: () => null,
  timeline: null,
};

const StateContext = createContext<Context>(defaultContext);
StateContext.displayName = 'Timeline';

interface IProps {
  children: ReactNode;
}

function TimelineProvider({ children }: IProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedMasterPeriod } = useTimeFilter();
  const { type, id } = useParamsItem();
  const searchParamsSelectedTime = searchParams.get('selectedTime');
  const [selectedTime, _setSelectedTime] = useState(searchParamsSelectedTime ? dayjs.utc(searchParamsSelectedTime) : dayjs.utc().subtract(1, 'd').startOf('day'));


  const { data: timeRange } = useAuthApi<AlertsPerPeriod, TimeRange[]>(
    `alerts-perperiod-${typeShorten[type as BatteryType]}-${id}-${selectedMasterPeriod.value}`,
    `dyndata/${typeShorten[type as BatteryType]}/${id}/alerts-perperiod?${selectedMasterPeriod.startStop}`,
    {
      enabled: !!id && !!selectedMasterPeriod.startStop,
      select: res => res.data,
    }
  );

  const timeline = useMemo(() => {
    if (!timeRange || timeRange.length === 0) {
      return null
    }

    return timeRange.map((interval) => ({
      start: interval.start,
      stop: interval.stop,
      value: interval.maxlevel ?? AlertLevel.UNKNOWN,
    }));
  }, [timeRange]);

  const selectedStopTime = useMemo(() => {
    let stop = selectedTime.add(1, 'day')
    if (timeRange) {
      timeRange.forEach((range: { start: string, stop: string }) => {
        if (selectedTime >= dayjs.utc(range.start) && selectedTime < dayjs.utc(range.stop)) {
          stop = dayjs.utc(range.stop)
        }
      })
    }
    return stop
  }, [selectedTime, timeRange])

  const setSelectedTime = useCallback((date: string, storeInSearchParams: boolean = false) => {
    const newDate = dayjs(date).format(dateFormat);
    _setSelectedTime(dayjs.utc(newDate));

    if (storeInSearchParams) {
      searchParams.set('selectedTime', newDate);
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (!searchParamsSelectedTime) return;
    setSelectedTime(searchParamsSelectedTime)
  }, [searchParamsSelectedTime, setSelectedTime]);

  const context = useMemo(() => (
    { selectedTime, setSelectedTime, selectedStopTime, timeline }),
    [selectedTime, setSelectedTime, selectedStopTime, timeline]
  );

  return <StateContext.Provider value={context}>{children}</StateContext.Provider>;
}

export const useTimeline = () => useContext(StateContext);

export default TimelineProvider;
